@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Open+Sans:wght@300;400;700;800&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

:root {
    --koolboks-white: #ffffff;
    --koolboks-black: #000000;
    --koolboks-darkgrey2: #161616;
    --koolboks-darkgrey: #1F1F1F;
    --koolboks-midgrey3: #505050;
    --koolboks-midgrey2: #4a4f46;
    --koolboks-midgrey: #282828;
    --koolboks-grey: #B3B3B3;
    --koolboks-teal: #00FFF0;
    --koolboks-purple1: #FF3AD4;
    --koolboks-glow: #6CFF26;
    --koolboks-red: #ff5829;
    --koolboks-orange: #F14A30;
    --koolboks-orange-alt: #e62002;
    --koolboks-orange2: #F14A30;
    --koolboks-orange3: #ffe3c1;
    --koolboks-green1: #0d7a3e;
    --koolboks-green2: #35b34a;
    --koolboks-good-samaritan: #3c6382;
    --koolboks-forest-blues: #0a3d62;
    --koolboks-soaring-eagle: #95afc0;
}

.checkbox {
    cursor: pointer;
}

.text-center {
    text-align: center;
    margin-bottom: 30px;
}

.icon {
    width: 70px;
    height: 70px;
    background: var(--koolboks-white);
    border-radius: 50%;
    margin: auto;
}

.icon.loading {
    background-color: var(--koolboks-white);
    background-image: url('./gif/loading2.gif');
    background-position: center;
    background-size: contain;
}

.icon.success {
    background-image: url('./svg/success.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
}

.icon.failed {
    background-image: url('./svg/failed.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
}

.result_message {
    color: #fff;
    font-family: 'DM Sans', sans-serif;
    margin-top: 30px;
    font-size: 20px;
    text-align: center;
    line-height: 35px;
}


.result_msg {
    color: #fff;
    font-family: 'DM Sans', sans-serif;
    margin-top: 30px;
    font-size: 15px;
    text-align: center;
    line-height: 35px;
}

.main {
    padding: 50px 20px;
    background: var(--koolboks-black);
    width: calc(100% - 40px);
    height: calc(100vh - 100px);
    overflow-y: auto;
}

.logo {
    width: 200px;
    height: 50px;
    background: url('./svg/koolboks_white.svg');
    margin: auto;
    background-repeat: no-repeat;
    background-size: contain;
}

.loan_widget {
    width: 300px;
    padding: 50px 50px 80px;
    /* border-radius: 20px; */
    margin: 20px auto;
    background: var(--koolboks-red);
    font-family: 'DM Sans', sans-serif;
    position: relative;
}

.loan_widget .fine_notes {
    position: absolute;
    background: var(--koolboks-white);
    right: 0;
    bottom: 0;
    padding: 5px 20px;
    font-size: 12px;
    border-top-left-radius: 15px;
}

.loan_widget input,
.loan_widget select {
    width: calc(100% - 20px);
    height: 50px;
    margin: 10px 0;
    border: none;
    font-size: 15px;
    /* border-radius: 5px; */
    font-family: 'DM Sans', sans-serif;
    /* background: var(--koolboks-white); */
    padding: 0px 10px;
}

.loan_widget input[type="checkbox"] {
    width: 30px;
    height: 30px;
}

.loan_widget select {
    width: 100%;
}

.loan_widget h2 {
    color: var(--koolboks-white);
    margin: 0px auto 20px;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
}

.loan_widget .submit {
    color: var(--koolboks-white);
    background: var(--koolboks-black);
    width: 100%;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    margin-top: 10px;
    font-family: 'DM Sans', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loan_widget .submit .loading {
    width: 30px;
    height: 30px;
    background: url('./gif/1495.gif');
    background-position: center;
    background-size: contain;
    margin-right: 10px;
}

.loan_widget .checkbox_wrapper {
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    display: flex;
}

.loan_widget .checkbox_text {
    color: var(--koolboks-white);
    margin-left: 10px;
    margin-top: 17px;
}

label,
.label {
    margin-top: 10px !important;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    color: var(--koolboks-orange3);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

label .icon,
.label .icon {
    height: 15px;
    width: 15px;
    margin: 0;
    margin-left: 10px;
}

.warning{
    font-size: 13px;
    color: var(--koolboks-midgrey);
    margin-top: 10px;
}

label .icon,
.label .icon.ok {
    background: url('./svg/check-mark.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
}

label .icon,
.label .icon.not_ok {
    background: url('./svg/cancel.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
}